<style lang="scss" scoped>
.page-order-zone-statistics {
    .buttons {
        .mobile-group-button {
          padding-left: 9px;
          padding-right: 9px;
        }
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover {
        background-color: #f0f0f0;
    }
    .point-list-btn {
        display: block;
        position: absolute;
        top: 6px;
        left: 5px;
        z-index: 10;
        background-color: #ffffff;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        font-size: 24px;
        padding: 1px 3px;
    }
    /deep/ .polygon-box {
        position: absolute;
        top: 45px;
        left: 5px;
        width: 400px;
        height: auto;
        z-index: 10;
        background: #fff;
        .el-table--border {
            border-top: 0;
        }
        .el-tabs__content {
            padding: 0 !important;
        }
        .el-table__body-wrapper {
            max-height: calc(90vh - 360px);
            overflow-y: auto;
        }
        .el-tabs--border-card {
            border-bottom: 0;
            box-shadow: 0 0px 0px 0 rgba(0,0,0,1), 0 0 0px 0 rgba(0,0,0,1);
        }
        .el-input__inner {
            padding: 0 5px;
        }
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        .polygon-box {
            .el-table__body-wrapper {
                max-height: calc(82vh - 40px);
                overflow-y: auto;
            }
        }
    }
    .polygon-btn {
        font-size: 12px;
        padding: 3px;
    }
}
</style>

<template>
    <div class="page-order-zone-statistics">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <div class="fl">
                    <el-button-group v-if="false">
                        <el-button type="primary" :plain="listQuery.locationType !== 0" size="medium" @click="changeTimeType(0)">用车</el-button>
                        <el-button type="warning" :plain="listQuery.locationType === 0" size="medium" @click="changeTimeType(1)">还车</el-button>
                    </el-button-group>
                    <el-button-group class="mrgr5 mrgb5">
                        <el-button :class="$root.isPc ? '' : 'mobile-group-button'" type="primary" v-for="(tab, index) in tabs" :plain="tabIndex !== index"
                            :key="'employee_tab_' + index" size="medium" @click="tabClick(index)">{{ tab }}</el-button>
                    </el-button-group>
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedTime" type="datetimerange" size="medium" v-show="isShowSelectTime"
                        :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" @change="_reset"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        align="right">
                    </el-date-picker>
                    <el-button class="mrgb5" type="primary" size="medium" @click="_reset()"  v-show="isShowSelectTime"><i class="fa fa-search"></i> 查询</el-button>
                </div>
                <div class="fr">
                    <el-button class="mrgb5" type="warning" size="medium" @click="openExportExcel()"><i class="fa fa-sign-out"></i> 导出</el-button>
                </div>
                <div class="clearb"></div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='mapZoneStatistics'>
            <span class="total-count col_primary" title="当前查询出的总订单数、已支付的订单总金额、总退款金额">总数量：{{ orderJson.length }}，总金额：{{ orderAmount }}元，总退款：{{ refundedAmount }}元</span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <template v-if="isOperationCustomerAreaConfig">
                <span class="point-list-btn" @click="isShowFold = !isShowFold" :title="isShowFold ? '收起' : '展开'">
                    <i :class="isShowFold ? 'el-icon-s-fold' : 'el-icon-s-unfold'"></i>
                </span>
                <div class="polygon-box" v-if="isShowFold">
                    <el-table :data="tableData" border size="small" highlight-current-row :row-class-name="tableRowClassName" @row-click="rowClick" 
                        show-summary :summary-method="getSummaries">
                        <el-table-column label="街道名称">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.name" size="mini" v-if="scope.row.isCanInput" placeholder="请输入街道名称"></el-input>
                                <span v-else>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量(单)" prop="count" width="80"></el-table-column>
                        <el-table-column label="金额(元)" prop="amount" width="80"></el-table-column>
                        <el-table-column label="操作" width="75">
                            <template slot-scope="scope">
                                <el-tooltip effect="dark" content="确定并退出当前编辑" placement="left" v-if="scope.row.isCanInput">
                                    <el-button class="polygon-btn mrgl5" type="success" size="mini" plain @click.stop="_sureEdit(scope.row, scope.$index)"><i class="el-icon-check"></i></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="还原并退出当前编辑" placement="right" v-if="scope.row.isCanInput && oldEditPaths.length > 0">
                                    <el-button class="polygon-btn mrgl5" type="warning" size="mini" plain @click.stop="_outEdit(scope.row, scope.$index)"><i class="el-icon-close"></i></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除当前街道" placement="right" v-if="!scope.row.isCanInput || (scope.row.isCanInput && oldEditPaths.length <= 0)">
                                    <el-button class="polygon-btn mrgl5" type="danger" size="mini" plain @click.stop="_delete(scope.row, scope.$index)"><i class="el-icon-delete"></i></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="fl">
                        <el-button class="mrgt5 mrgl5 mrgb5" size="small" type="primary" @click="addPolygonEdit()">新增</el-button>
                    </div>
                    <div class="fr">
                        <!--<el-button class="mrgt5 mrgr5 mrgb5" size="small" @click="_reset()">还原全部</el-button>-->
                        <el-button class="mrgt5 mrgr5 mrgb5" size="small" type="success" @click="_save()">提交保存</el-button>
                    </div>
                    <div class="clearb"></div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import permission from "@/mixins/permission"
import * as funMaintenance from "@/api/maintenance"
import moment from 'moment'
import { OperationZoneStatistics } from "@/mixins/commonPageExport"

let dataVars = {
    map: null, // 地图
    polygonMap: null, //多边形覆盖物
    curPolygonEdit: null, // 当前可编辑的对象
    polygonEdits: [], // 街道列表地图对象
    massMarker: null
}
export default {
    name: "pageOrderZoneStatistics",
    mixins: [permission],
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "说明：地图左侧为街道配置，编辑街道可鼠标拖动编辑区域进行微调，统计的数据为选择时间段的所有已支付完成的订单数据。订单点位是以用车开锁时间计算。"
            },
            listQuery: {
                area: "",
                locationType: 0,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: ""
            },
            orderJson: [],
            orderAmount: 0,
            refundedAmount: 0,
            isScreen: false,
            selectedTime: [moment().subtract(1, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            tabIndex: 0,
            tabs: ["昨日", "近7日", "本月", "上月", "今年", "去年", "自定义"],
            isShowSelectTime: false,
            fillColors: ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc", "#ff6600", "#ff0000"],
            isShowFold: true,
            coverPaths: [], // 服务区多边形点位
            tableData: [], // 街道列表数据
            curPolygonEditIndex: -1,
            oldEditPaths: [], // 纪录编辑前的原始数据
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                // 已经创建了地图则直接重设多边形区域
                this._reset()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        // 获取当前服务器信息，得到默认的服务区多边形点位path
        this.getCurrentAreaData()
        if (dataVars.map) {
            // 已经创建了地图则直接重设多边形区域
            this.setPolygonContains()
        } else {
            // 创建地图
            await this.initMap()
        }
        // 获取订单数据等
        await this.getDataList()
    },
    unmounted() {
        dataVars.map?.destroy()
    },
    methods: {
        /****************数据处理***************/
        // 判断点位是否在街道内，并更新到列表中
        tranceAreaOrderData() {
            /* {
                amount: 0,
                date: "2023-10-12T00:00:00+08:00",
                lat: 30.692959,
                lng: 104.03601
            } */
            let orders = this.orderJson
            let tables = JSON.parse(JSON.stringify(this.tableData))
            for (let i in orders) {
                let item = orders[i]
                let amapLngLat = new AMap.LngLat(item.lng, item.lat)
                for (let k in tables) {
                    let table = tables[k]
                    let isIn = dataVars.polygonEdits[k].contains(amapLngLat)
                    if (isIn) {
                        table.amount = window.$util.operationAddOrSub("+", table.amount, item.amount)
                        table.count++
                        break
                    }
                }
            }
            for (let index in tables)  {
                let temp = tables[index]
                temp.amount =  window.$util.operationDiv(temp.amount, 100)
                temp.amount = window.$util.toFixed(temp.amount, 2)
                dataVars.polygonEdits[index].setExtData(temp)
            }
            this.tableData = tables
        },
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
          let psTime = ""
          let peTime = ""
          if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
              psTime = moment(this.selectedTime[0]).format("YYYY-MM-DD HH:mm:ss")
          }
          if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
              peTime = moment(this.selectedTime[1]).format("YYYY-MM-DD HH:mm:ss")
          }
          this.listQuery.beginDate = psTime
          this.listQuery.endDate = peTime
          this.selectedTime = [this.listQuery.beginDate, this.listQuery.endDate]
        },
        async getDataList() {
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            this.orderJson = []
            this.orderAmount = 0
            this.refundedAmount = 0
            await funMaintenance.GetCustomerAreaOrders(this.listQuery).then(response => {
                this.orderJson = response.items
                // 总金额
                this.orderAmount = response.total_rechargeCost // response.reduce((a, b) => { return a + b.amount }, 0)
                this.orderAmount =  window.$util.operationDiv(this.orderAmount, 100)
                this.orderAmount = window.$util.toFixed(this.orderAmount, 2)
                // 总退款
                this.refundedAmount = response.refunded
            })
            // 设置海量点
            this.setMassMarkData()
            // 获取街道数据
            await this.getCustomerAreaConfig()
            // 判断点位并更新到列表
            this.tranceAreaOrderData()
            window.$common.closeFullLoading()
        },
        // 设置海量点
        setMassMarkData() {
            // 点位数小于20000，并且只看一天的数据才显示
            if (this.orderJson.length > 20000 || !moment(this.listQuery.beginDate).isSame(this.listQuery.endDate, 'day')) {
                return false
            }
            let massData = this.orderJson.map(x => {
                let temp = {
                    lnglat: [x.lng, x.lat],
                    style: 2
                }
                return temp
            })
            // document.write(JSON.stringify(massData))
            setTimeout(() => {
                dataVars.massMarker.setData(massData)
            }, 2000)
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverPaths = areaItem.pointList || []
            }
        },
        // 获取街道数据，并设置地图区域
        async getCustomerAreaConfig() {
            dataVars.polygonEdits = []
            this.tableData = []
            await funMaintenance.GetCustomerAreaConfig({ area: this.$store.getters.serviceArea }).then(res => {
                dataVars.polygonEdits = res && res.map((x, i) => {
                    let temp = x
                    temp.id = "st" + i
                    temp.isCanInput = false
                    temp.count = 0
                    temp.amount = 0
                    temp.index = i
                    // 创建多边形
                    let polygon = this.createPolygon(x.points, temp)
                    this.tableData.push(temp)
                    dataVars.map.add(polygon)
                    return polygon
                }) || []
            })
        },
        tabClick(index) {
            this.tabIndex = index
            switch(index) {
                case 0: {
                    // 昨日
                    this.selectedTime = [moment().subtract(1, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")]
                    break
                }
                case 1: {
                    // 近7日
                    this.selectedTime = [moment().subtract(7, "d").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")]
                    break
                }
                case 2: {
                    // 本月
                    this.selectedTime = [moment().startOf("month").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")]
                    break
                }
                case 3: {
                    // 上月
                    this.selectedTime = [moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD 23:59:59")]
                    break
                }
                case 4: {
                    // 今年
                    this.selectedTime = [moment().startOf("year").format("YYYY-MM-DD 00:00:00"), moment().format("YYYY-MM-DD 23:59:59")]
                    break
                }
                case 5: {
                    // 去年
                    this.selectedTime = [moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD 00:00:00"), moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD 23:59:59")]
                    break
                }
            }
            if (index !== 6) {
                this.isShowSelectTime = false
                this._reset()
            } else {
                this.selectedTime = []
                this.isShowSelectTime = true
            }
        },
        changeTimeType(val) {
            this.listQuery.locationType = val
            this._reset()
        },
        // 时间段选择
        changeTime(arr) {
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
            }
        },
        /*****************地图数据操作******************/
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.PolygonEditor"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 14
            dataVars.map = new AMap.Map("mapZoneStatistics", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // this.moveMap()
            // 设置海量点
            this.setMassMarks()
            // 设置多边形数据
            this.setPolygonContains()
        },
        // 海量点
        setMassMarks() {
            dataVars.massMarker = new AMap.MassMarks([], {
                opacity: 0.8,
                cursor: 'pointer',
                style: {
                    url: '/static/images/map/mass1.png',
                    anchor: new AMap.Pixel(4, 4),
                    size: new AMap.Size(8, 8)
                }
            })
            dataVars.massMarker.setMap(dataVars.map)
        },
        // 地图移动，显示隐藏海量标记
        moveMap() {
            let _setTimeout = null
            let isMapMoving = false
            // 停止变动，显示海量点
            let stopMove = () => {
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = setTimeout(() => {
                    if (isMapMoving) {
                        this.changeMassMarker(true)
                        isMapMoving = false
                    }
                }, 2000)
            }
            // 地图移动
            dataVars.map.on("mapmove", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMassMarker()
            })
            dataVars.map.on("moveend", () => {
                stopMove()
            })
            // 地图缩放
            dataVars.map.on("zoomstart", () => {
                isMapMoving = true
                _setTimeout && clearTimeout(_setTimeout)
                _setTimeout = null
                this.changeMassMarker()
            })
            dataVars.map.on("zoomend", () => {
                stopMove()
            })
        },
        // 显示隐藏海量点
        changeMassMarker(type) {
            if (type) {
                if (this.curPolygonEditIndex === -1) {
                    dataVars.massMarker.show()
                }
            } else {
                dataVars.massMarker.hide()
            }
        },
        // 设置服务区多边形数据
        async setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 计算中心点
            let sumLng = path.reduce((a, b) => { return a + b[0] }, 0)
            let cenLng = sumLng / path.length
            let sumLat = path.reduce((a, b) => { return a + b[1] }, 0)
            let cenLat = sumLat / path.length
            this.moveToCenter({ lng: cenLng, lat: cenLat})
        },
        // 创建街道区域多边形
        createPolygon(path, item) {
            item = item || {
                id: "st" + dataVars.polygonEdits.length,
                name: "",
                points: [],
                isCanInput: true,
                count: 0,
                amount: 0,
                index: dataVars.polygonEdits.length
            }
            let fillIndex = item.index % this.fillColors.length
            let color = this.fillColors[fillIndex]
            let polygon = new AMap.Polygon({
                path: path,
                strokeColor: color, 
                strokeWeight: 2,
                strokeOpacity: 0.5,
                fillOpacity: 0.3,
                fillColor: color,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5],
                bubble: true,
                extData: item
            })
            polygon.on("dragstart", (e) => {
                dataVars.curPolygonEdit && dataVars.curPolygonEdit.close()
            })
            polygon.on("dragend", (e) => {
                dataVars.curPolygonEdit && dataVars.curPolygonEdit.open()
            })
            return polygon
        },
        // 新增多边形
        addPolygonEdit() {
            let isInputIndex = this.tableData.find(x => x.isCanInput)
            if (dataVars.curPolygonEdit) {
                this.warningMsg("有正在编辑的区域，请先完成编辑!")
                return false
            }
            let pos = dataVars.map.getCenter()
            let baseNum = 0.0008
            let path = [
                [pos.lng - baseNum, pos.lat + baseNum],
                [pos.lng + baseNum, pos.lat + baseNum],
                [pos.lng + baseNum, pos.lat - baseNum],
                [pos.lng - baseNum, pos.lat - baseNum]
            ]
            // 创建多边形
            let polygon = this.createPolygon(path)
            dataVars.map.add(polygon)
            dataVars.polygonEdits.push(polygon)
            this.tableData.push(polygon.getExtData())
            // 编辑多边形
            this.editPolygonEdit(dataVars.polygonEdits.length - 1)
        },
        // 编辑多边形
        editPolygonEdit(index) {
            // 编辑配置config
            let fillColor = ""
            let editorConfig = {
                controlPoint: {
                    radius: 5
                },
                midControlPoint: {
                    radius: 5
                }
            }
            // 保存当前的下标
            this.curPolygonEditIndex = index
            // 隐藏海量点
            this.changeMassMarker()
            // 设置可以拖动
            dataVars.polygonEdits[index].setOptions({ draggable: true })
            // 创建编辑多边形对象
            dataVars.curPolygonEdit = new AMap.PolygonEditor(dataVars.map, dataVars.polygonEdits[index], editorConfig)
            // 开始编辑
            dataVars.curPolygonEdit.open()
        },
        _save() {
            let index = this.curPolygonEditIndex
            let editIndex = 0
            for (let i in this.tableData) {
                let item = this.tableData[i]
                if (!item.name) {
                    editIndex = Number(i) + 1
                    break
                }
            }
            if (editIndex) {
                this.warningMsg(`请配置第${editIndex}条数据中街道名称!`)
                return false
            }
            // 保存前清除编辑状态
            this._clearCurEdit()
            // 保存的时候如果有编辑状态，则需要完成编辑
            if (index !== -1) {
                this._sureCurEdit(index)
            }
            let commitData = {
                area: this.listQuery.area,
                data: this.tableData.map(x => {
                    let temp = {
                        id: x.id,
                        name: x.name,
                        points: x.points || []
                    }
                    return temp
                })
            }
            // 提交保存
            funMaintenance.SetCustomerAreaConfig(commitData).then(res => {
                this.commonSuccess()
            })
        },
        // 还原数据，跟从新刷新界面一个道理
        async _reset() {
            this._clearCurEdit()
            this._clearPolygonEditAll()
            dataVars.map.clearMap()
            this.setPolygonContains()
            this.getDataList()
        },
        // 确定完成编辑
        _sureEdit(row, index) {
            if (!row.name) {
                this.warningMsg("请配置街道名称!")
                return false
            }
            row.isCanInput =  false
            // 清除当前编辑
            this._clearCurEdit()
            // 设置当前编辑的街道数据
            this._sureCurEdit(index)
        },
        // 设置当前编辑的街道数据
        _sureCurEdit(index) {
            // 设置区域不可拖动
            dataVars.polygonEdits[index].setOptions({ draggable: false })
            // 设置参数
            let paths = dataVars.polygonEdits[index].getPath()
            this.tableData[index].isCanInput = false
            this.tableData[index].points = paths.map(x => {
                let temp = [x.lng, x.lat]
                return temp
            })
            dataVars.polygonEdits[index].setExtData(this.tableData[index])
        },
        _outEdit(row, index) {
            if (this.oldEditPaths.length <= 0) {
                return false
            }
            row.isCanInput = false
            row.points = this.oldEditPaths.map(x => {
                let temp = [x.lng, x.lat]
                return temp
            })
            this._clearCurEdit()
            dataVars.polygonEdits[index].setOptions({ draggable: false })
            dataVars.polygonEdits[index].setPath(row.points)
            dataVars.polygonEdits[index].setExtData(row)
            this.curOldPolygon = []
            
        },
        _delete(row, index) {
            this.confirm("确定要删除当前配置吗？").then(() => {
                this._clearCurEdit()
                this._clearPolygonEdit(index)
            })
        },
        // 清除当前编辑对象
        _clearCurEdit() {
            dataVars.curPolygonEdit && dataVars.curPolygonEdit.close()
            dataVars.curPolygonEdit && dataVars.map.remove(dataVars.curPolygonEdit)
            dataVars.curPolygonEdit = null
            this.curPolygonEditIndex = -1
            this.oldEditPaths = []
            this.changeMassMarker(true)
        },
        // 清除指定街道对象
        _clearPolygonEdit(index) {
            dataVars.map.remove(dataVars.polygonEdits[index])
            dataVars.polygonEdits.splice(index, 1)
            this.tableData[index] && this.tableData.splice(index, 1)
        },
        _clearPolygonEditAll() {
            this.tableData = []
            for (let i in dataVars.polygonEdits) {
                this._clearPolygonEdit(i)
            }
        },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex
        },
        rowClick(row, column, event) {
            if (dataVars.curPolygonEdit && this.curPolygonEditIndex !== row.index) {
                this.warningMsg("有正在编辑的区域，请先完成编辑!")
                return false
            }
            if (!row.isCanInput) {
                row.isCanInput = true
                dataVars.polygonEdits[row.index].setExtData(row)
                this.editPolygonEdit(row.index)
                this.oldEditPaths = dataVars.polygonEdits[row.index].getPath()
            }
        },
        getSummaries(params) {
            const { columns, data } = params
            let others = []
            columns.forEach((column, index) => {
                if (index === 0) {
                    others[index] = '其他'
                    return false
                }
                const values = data.map(item => Number(item[column.property]))
                if (!values.every(value => isNaN(value))) {
                    let sum = values.reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            // 金额先x100，转成分
                            if (column.property === "amount") {
                                curr = window.$util.operationMulti(curr, 100)
                            }
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    if (column.property === "count") {
                        others[index] = this.orderJson.length - sum
                    }
                    if (column.property === "amount") {
                        let oAmount = window.$util.operationMulti(this.orderAmount, 100)
                        let result = window.$util.operationAddOrSub("-", oAmount, sum)
                        result = window.$util.operationDiv(result, 100)
                        result = window.$util.toFixed(result, 2)
                        others[index] = result
                    }
                }
            })
            return others
        },
        moveToCenter(item) {
            this.centerPos = [item.lng, item.lat]
            dataVars.map.setCenter(this.centerPos)
        },
        commonSuccess(val) {
            let tips = val || "操作成功!"
            this.alert(tips).then(() => {
                this._reset()
                window.$common.closeFullLoading()
            })
        },
        openExportExcel() {
            window.$common.fullLoading()
            setTimeout(() => {
                try {
                    if (this.orderJson.length > 0) {
                        let tableDatas = []
                        let days = moment(this.listQuery.endDate).diff(moment(this.listQuery.beginDate), "d")
                        // 先生成时间
                        for (let i = 0; i <= days; i++) {
                            let starDay = moment(this.listQuery.beginDate).format("YYYY-MM-DD")
                            let temp = {
                                date: moment(starDay).add(i, "d").format("YYYY-MM-DD"),
                                other: { name: "其他", count: 0, amount: 0}
                            }
                            // 给所有的都生成对应的街道对象
                            for (let m in this.tableData) {
                                let street = this.tableData[m]
                                temp[street.id] = {
                                    name: street.name,
                                    count: 0,
                                    amount: 0
                                }
                            }
                            // 数据循环获取并取得数据
                            let result = {
                                totalCount: 0, // 当前总数
                                totalAmount: 0, // 当前总金额
                                containCount: 0, // 当前在街道内的数量
                                containAmount: 0 // 当前在街道内的总金额
                            }
                            for (let k in this.orderJson) {
                                let order = this.orderJson[k]
                                let lngLat = new AMap.LngLat(order.lng, order.lat)
                                // 同一日期的数据才执行计算
                                if (moment(temp.date).isSame(order.date, "d")) {
                                    result.totalCount++
                                    result.totalAmount = window.$util.operationAddOrSub("+", result.totalAmount, order.amount)
                                    // 街道循环
                                    for (let m in this.tableData) {
                                        let street = this.tableData[m]
                                        let isIn = dataVars.polygonEdits[m].contains(lngLat)
                                        // 订单日期和生成的日期相等
                                        if (isIn) {
                                            temp[street.id] = {
                                                name: street.name,
                                                count: temp[street.id].count + 1,
                                                amount: window.$util.operationAddOrSub("+", temp[street.id].amount, order.amount)
                                            }
                                            // 包含在街道内的统计
                                            result.containCount++
                                            result.containAmount = window.$util.operationAddOrSub("+", result.containAmount, order.amount)
                                            break
                                        }
                                    }
                                }
                            }
                            temp.other.count = result.totalCount - result.containCount
                            temp.other.amount = window.$util.operationAddOrSub("-", result.totalAmount, result.containAmount)
                            tableDatas.push(temp)
                        }
                        // 导出配置
                        let exportTitle = ""
                        let exportTime = ""
                        let startDate = moment(this.listQuery.beginDate).format("YYYY-MM-DD")
                        let endDate = moment(this.listQuery.endDate).format("YYYY-MM-DD")
                        if (startDate === endDate) {
                            exportTime = startDate
                        } else {
                            exportTime = `${startDate}至${endDate}`
                        }
                        exportTitle = `${this.$store.getters.serviceArea}对账统计(${exportTime}).xlsx`
                        OperationZoneStatistics(exportTitle, tableDatas)
                    } else {
                        setTimeout(() => {
                            this.alert("没有需要导出的数据!")
                        }, 10)
                    }
                } catch(ex) {
                    this.errorMsg("导出的数据出错!")
                    window.$common.closeFullLoading()
                }
            }, 500)
        }
    }
}
</script>